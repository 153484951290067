//* EXTERNAL LIBS
import React, { FC, useState, useMemo, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

//* EXTERNAL LIBS --> MUI
import { HourglassEmpty, Check, Publish } from '@mui/icons-material';
import { Grid } from '@mui/material';

//* EXTERNAL LIBS --> XPAND-UI
import { LoadingOverlay, Table, PageTitle, Dialog, DualTransferList } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

//* TYPINGS
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { IResumeUserDetail } from 'typings/store/admin/skillsAndResumes';

//* PROJECT IMPORTS [LIB / PAGES ]
import { addNotification } from 'lib/utils/notifications';
import { START_API_PATH } from 'lib/utils/constants';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import ResumeDetails from './ResumeDetails';
import { useStyles } from './styles';
import { SearchResumesProps } from '.';

//* LOCAL ASSETS
import imageURL from '../../../../assets/images/status diagrams/Diagram - Skills Reminder - XPECP.png';
import { getLSField } from 'lib/utils/cookies';

//* COMPONENT INTERFACES

interface IListSearchResumes {
	id: string;
	name: string;
	resumeId: number;
}

//* COMPONENT
const SearchResumes: FC<SearchResumesProps> = ({
	skillsAndResumes,
	personalInfo,
	filters,
	getSearchResumes,
	getSystemSkills,
	exportResume,
	exportMultipleResumes,
	sendPostRemindResume,
	clearSkillsAndResumesError,
	setSearchResumesPageFilter
}) => {
	const classes = useStyles();
	const { listSearchResumes, loading, error } = skillsAndResumes;
	const { systemSkills } = personalInfo;
	const { searchResumesFilter } = filters;

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(searchResumesFilter);

	useEffect(() => {
		setSearchResumesPageFilter(searchValue);
	}, [searchValue]);

	const [openDetails, setOpenDetails] = useState<number | null>(null);
	const [exportMultipleResumesModalOpen, setExportMultipleResumesModalOpen] = useState<boolean>(false);

	const [resumesList, setResumesList] = useState<IListSearchResumes[]>([] as IListSearchResumes[]);
	const [selectedResumes, setSelectedResumes] = useState<IListSearchResumes[]>([] as IListSearchResumes[]);

	// STATUS DIAGRAM MODAL
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	useEffect(() => {
		if (!error && (!systemSkills || systemSkills.length === 0)) {
			getSystemSkills();
		}
	}, [systemSkills]);

	useEffect(() => {
		if (!listSearchResumes) {
			getSearchResumes();
		} else {
			// Filter to only show the users that have a resume, in order to be used in the Multiple Exports Popup
			setResumesList(
				listSearchResumes.users
					.map(e =>
						e.resumeId
							? {
									id: e.username,
									name: e.name,
									resumeId: e.resumeId
							  }
							: null
					)
					.filter(e => e !== null) as IListSearchResumes[]
			);
		}
	}, [listSearchResumes]);

	/** handle submit */
	const handleSubmit = () => {
		//
		let timer = 5;
		if (selectedResumes.length > 5) {
			timer = selectedResumes.length;
		}
		exportMultipleResumes(selectedResumes.map(e => e.resumeId));
		addNotification('info', 'Please wait, your download will begin shortly', timer, { container: 'top-right' });
		setExportMultipleResumesModalOpen(false);
	};

	/** handle dual transfer list component changes */
	const handleListChanges = (l: IListSearchResumes[], r: IListSearchResumes[]) => setSelectedResumes(r);

	const buttonExportResume = (id: number) => {
		exportResume(id);
	};

	const buttonRemindResume = (id: string) => {
		const payload = { username: id };
		sendPostRemindResume(payload);
	};

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<IResumeUserDetail>[] = useMemo(
		() => [
			{
				label: '',
				id: 'photo',
				isSortable: false,
				// eslint-disable-next-line react/prop-types
				format: ({ username }) => (
					<div className={classes.avatarContainer}>
						<img
							src={`${START_API_PATH}/admin/users/${username}/photo?small=true`}
							alt=""
							className={classes.avatarImage}
						/>{' '}
					</div>
				)
			},
			{ label: 'User', id: 'username', accentColumn: true, width: '10%', maxWidth: '10%' },
			{ label: 'Name', id: 'name' },

			{
				label: 'Last Update Date',
				type: 'date',
				id: 'updateDate',
				width: '30%',
				maxWidth: '30%',
				format: (row, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'Status',
				id: 'statusParsed',
				format: (row, text: string) =>
					text === 'Ok' ? (
						<div>
							<Check color="primary" /> {text}
						</div>
					) : (
						<div>
							<HourglassEmpty color="primary" /> {text}
						</div>
					)
			}
		],
		[]
	);

	// this is used on table sort and filter
	const tableData = useMemo(
		() =>
			listSearchResumes?.users?.map(e => ({
				...e,
				id: e.username,
				statusParsed: e.resumeId ? 'Ok' : 'Due',
				lastUpdateDateParsed: e.updateDate ? parseDateToShow(e.updateDate) : ''
			})) || [],
		[listSearchResumes]
	);

	const tableActions: ITableAction<IResumeUserDetail>[] = useMemo(
		() => [
			{
				id: 'searchResumesMenu',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'searchResumesMenuResend',
						label: 'Resend',
						disabled: row => (Boolean(row.resumeId) || getLSField('impersonate_userInfo') ? true : false),
						onClick: row => {
							buttonRemindResume(row.username);
						}
					},
					{
						id: 'searchResumesMenuViewDetails',
						label: 'View Details',
						disabled: row => Boolean(!row.resumeId) || (getLSField('impersonate_userInfo') ? true : false),
						onClick: row => row.resumeId && setOpenDetails(row.resumeId)
					},
					{
						id: 'searchResumesMenuExport',
						label: 'Export',
						disabled: row => Boolean(!row.resumeId) || (getLSField('impersonate_userInfo') ? true : false),
						onClick: row => row.resumeId && buttonExportResume(row.resumeId)
					}
				]
			}
		],
		[]
	);

	const pageTitleActions = useMemo(
		() => [
			{
				id: 'exportMultipleResumes',
				type: 'file',
				onClick: () => {
					setExportMultipleResumesModalOpen(true);
				},
				icon: <Publish />,
				label: 'Export Multiple Resumes'
			}
		],
		[]
	);

	const exportMultipleResumesActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setExportMultipleResumesModalOpen(false)
		},
		{
			id: 'submit',
			label: 'Export',
			color: 'primary',
			type: 'submit',
			form: 'form-export-multiple-resumes',
			variant: 'contained',
			onClick: () => handleSubmit(),
			disabled: selectedResumes.length <= 0 || getLSField('impersonate_userInfo')
		}
	];

	if (error) return handleErrorPage(error, clearSkillsAndResumesError);

	const isLoading = listSearchResumes === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}
			<PageTitle actions={pageTitleActions} />
			<Table
				tableData={tableData}
				columns={tableColumns}
				actions={tableActions}
				defaultSortColumn="name"
				handleSearch={{ searchValue, setSearchValue }}
				handleDiagramModal={{ diagramModal, setDiagramModal }}
			/>

			<Dialog
				modal={{
					open: Boolean(exportMultipleResumesModalOpen),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setExportMultipleResumesModalOpen(false);
					},
					content: (
						<Grid>
							<DualTransferList
								title="Resumes"
								leftList={resumesList}
								rightList={[]}
								payloadId="id"
								payloadLabel="name"
								handleChanges={handleListChanges}
							/>
						</Grid>
					)
				}}
				title="Export Multiple Resumes"
				maxWidth="lg"
				actions={exportMultipleResumesActions}
				scroll="body"
			/>

			<Dialog
				fullScreen
				customClasses={{ fullScreenContent: classes.fullScreenContent }}
				modal={{
					open: Boolean(openDetails),
					handleClose: () => setOpenDetails(null),
					content: (
						<>
							{' '}
							<Scrollbars
								style={{ height: 'calc(100vh - 220px)', overflowX: 'hidden' }}
								renderTrackHorizontal={(props: any) => (
									<div {...props} style={{ display: 'none' }} className="track-horizontal" />
								)}>
								<ResumeDetails resumeId={openDetails} systemSkills={systemSkills} />{' '}
							</Scrollbars>
						</>
					)
				}}
				actions={[]}
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(SearchResumes);
