import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, POST, EXPORTFILEPOST, GET, EXPORTFILE } from 'lib/handlers/requestTypes';

//* Xtracker - MyTimesheets */

export const fetchWeekViewData = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timesheets', payload);

export const fetchWeekViewDataForCopy = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timesheets/copyTimesheet', payload);

export const fetchTimesheetsPeriodListFiltersData = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timesheets/period/filters', payload);

export const fetchDayViewData = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timesheets/day', payload);

export const fetchPrintData = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILEPOST, '/user/xtracker/timesheets/print', payload);

export const fetchImportTimesheet = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timesheets/import', payload, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});

export const fetchMoveTimeEntry = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timesheets/timeEntry/move', payload);

export const fetchCopySave = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timesheets/copy/save', payload);

export const fetchExportTimesheet = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILEPOST, '/user/xtracker/timesheets/export', payload);

export const fetchSaveTimesheet = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timesheets/save', payload);

export const fetchAddAttachment = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timesheets/add/attachment', payload);

export const fetchDeleteAttachment = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timesheets/delete/attachment', payload);

export const fetchListAttachment = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timesheets/attachment/list', payload);

export const fetchAttachmentDownload = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILEPOST, '/user/xtracker/timesheets/attachment/download', payload);

export const fetchImportTemplateDownload = async (): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILEPOST, '/user/xtracker/timesheets/import/download');

//* Xtracker - My Expenses */

export const fetchExpensesData = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/expenses', payload);

export const fetchExpenseSheetData = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/expenses/sheet', payload);

export const fetchNewExpenseLineData = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/expense-entry/load', payload);

export const deleteExpensesData = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/expenses/delete', payload);

export const sendExpenseSheet = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/expenses/newExpenseSheet', payload);

export const fetchExpensesAuditData = async (payload: {
	expenseSheetId: string;
}): Promise<OriginalResponse | Success> => restCall(POST, '/user/xtracker/expenses/audit', payload);

export const copyExpenseSheetData = async (payload: {
	expenseSheetId: string;
	username: string;
}): Promise<OriginalResponse | Success> => restCall(POST, '/user/xtracker/expenses/copy', payload);

export const fetchExpenseListAttachment = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/expenses/attachment/list', payload);

export const fetchExpenseAddAttachment = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/expenses/attachment/add', payload);

export const fetchExpenseDeleteAttachment = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/expenses/attachment/delete', payload);

export const fetchExpenseDownloadAttachment = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILEPOST, '/user/xtracker/expenses/attachment/download', payload);

export const deleteExpenseEntryData = async (payload: {
	accountEmployeeExpenseEntryId: number;
}): Promise<OriginalResponse | Success> => restCall(POST, '/user/xtracker/expenses/edit/expense-entry/delete', payload);

export const fetchPrintExpenseSheetData = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILEPOST, '/user/xtracker/expenses/print', payload);

export const updateExpenseSheet = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/expenses/update', payload);

export const sendNewExpenseLine = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/expense-entry/newExpenseLine', payload);

export const fetchSubmitExpenseSheet = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/expenses/submit', payload);

//* Xtracker - My Timeoff */

export const fetchTimeoffData = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timeoff', payload);

export const fetchSaveTimeoff = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timeoff/save', payload);

export const sendDeleteTimeoff = async (payload: {
	accountEmployeeId: number;
	timeOffRequestId: string;
}): Promise<OriginalResponse | Success> => restCall(POST, '/user/xtracker/timeoff/delete', payload);

export const sendSubmitTimeoff = async (payload: {
	accountEmployeeId: number;
	timeOffRequestsId: { id: string }[];
}): Promise<OriginalResponse | Success> => restCall(POST, '/user/xtracker/timeoff/submit', payload);

export const sendUnsubmitTimeoff = async (payload: {
	accountEmployeeId: number;
	timeOffRequestId: string;
}): Promise<OriginalResponse | Success> => restCall(POST, '/user/xtracker/timeoff/unsubmit', payload);

export const fetchTimeoffRequestApprovalDetailsData = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/timeoff/approval-details', payload);

export const fetchExportTimeoffICAL = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILEPOST, '/user/xtracker/timeoff/export/ical', payload);

export const fetchExportTimeoffPDF = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(EXPORTFILEPOST, '/user/xtracker/timeoff/export/pdf', payload);

export const fetchApprovalsTimesheet = async (payload: unknown): Promise<OriginalResponse | Success> =>
	restCall(POST, '/user/xtracker/approvals/timesheet/load', payload);
