import React, { ComponentType } from 'react';
import { Route, Redirect, RouteComponentProps, StaticContext } from 'react-router';
import { getLSField } from 'lib/utils/cookies';
import { useIdToken } from 'pages/General/Login/utils';

const checkRolesNeeded = (roles: string[], perms: Record<string, string>[]): boolean =>
	roles.some(roleNeeded => perms.some(({ displayName }) => displayName.toLowerCase() === roleNeeded.toLowerCase()));
interface AuthProps {
	path: string;
	exact: boolean;
	redirect?: string;
	roles?: string[];
	title?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component?: ComponentType<RouteComponentProps<any, StaticContext, unknown>> | ComponentType<any> | undefined;
}

const AuthRoute: React.FC<AuthProps> = ({ path, exact, redirect, roles, component, title }: AuthProps) => {
	const isAuthed = useIdToken();
	const userPermStorage = getLSField('impersonate_userPermissions')
		? getLSField('impersonate_userPermissions')
		: getLSField('userPermissions');

	const userPerms = (userPermStorage && JSON.parse(userPermStorage)) || null;

	const route = <Route path={path} exact={exact} component={component} />;

	if (title) {
		document.title = title;
	}

	if (redirect) return <Redirect to={redirect} />;

	// AUTHED BUT CHECK USER PERMISSIONS (redirect to 403 when needed)
	if (isAuthed && roles) {
		if (!checkRolesNeeded(roles, userPerms)) return <Redirect to="/403" />;
	}

	// AUTHED AND USER HAS PERMISSIONS
	return route;
};
AuthRoute.defaultProps = {
	redirect: undefined,
	roles: undefined,
	title: undefined,
	component: undefined
};
export default AuthRoute;
