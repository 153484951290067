import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import systemReducer from './system/reducer';

// General Reducers
import generalReducer from './general/apps/reducer';
import personalInfoReducer from './personalInfo/reducer';
import usersReducer from './administration/users/reducer';
import CompanyReducer from './company/reducer';
import FiltersReducer from './filters/reducer';

// Administration Reducers
import admissionsReducer from './administration/admissions/reducer';
import proposalsReducer from './administration/proposals/reducer';
import employeeSalaryCategoriesReducer from './administration/employeeSalaryCategories/reducer';
import SkillsAndResumesReducer from './administration/skillsAndResumes/reducer';
import AdministrationReducer from './administration/administration/reducer';
import ProjectsAndClientsReducer from './administration/projectsAndClients/reducer';
import PlannerReducer from './administration/planner/reducer';
import BudgetReducer from './administration/budget/reducer';
import CollaborationTypesManagementReducer from './administration/collaborationTypesManagement/reducer';
import NotificationsReducer from './notifications/reducer';
import FormFieldsReducer from './administration/formFields/reducer';
import GuidesReducer from './guides/reducer';

const createRootReducer = (history: History): Reducer =>
	combineReducers({
		// APP Reducers
		router: connectRouter(history),
		system: systemReducer,

		// General Reducers
		general: generalReducer,
		personalInfo: personalInfoReducer,

		// ------ ADMINISTRATION
		// Users Reducers
		users: usersReducer,

		// Admissions Reducers
		admissions: admissionsReducer,

		// PROPOSALS Reducers
		proposals: proposalsReducer,

		// EMPLOYEE SALARY CATEGORIES Reducers
		employeeSalary: employeeSalaryCategoriesReducer,

		// EMPLOYEE CATEGORIES MANAGEMENT Reducers
		collaborationTypesManagement: CollaborationTypesManagementReducer,

		// Skills and Resumes Reducers
		skillsAndResumes: SkillsAndResumesReducer,

		// Administration Reducers
		administration: AdministrationReducer,

		// Projects and Clients Reducers
		projectsAndClients: ProjectsAndClientsReducer,

		// Projects and Clients Reducers
		planner: PlannerReducer,

		// Budget Reducers
		budget: BudgetReducer,

		// Company Reducers
		company: CompanyReducer,

		// Filters Reducers
		filters: FiltersReducer,

		// Notifications Reducers
		notifications: NotificationsReducer,

		// Form Fields Reducers
		formFields: FormFieldsReducer,

		// Guides Reducers
		guides: GuidesReducer
	});

export default createRootReducer;
