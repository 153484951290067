/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { Dispatch } from 'redux';
import { fetchPlannerData, fetchFiltersData, postPlannerData, postFilters } from 'lib/api/administration/planner';
import { OriginalResponse, Success } from 'lib/handlers/requestTypes';
import { IEvent, IFilters } from 'typings/store/admin/planner';
import {
	setLoading,
	setPlannerData,
	setFiltersData,
	setPostNewPlannerData,
	setFilteredData,
	clearPlannerData,
	clearAllData,
	setPlannerError,
	setPlannerDataCreate

} from './actions';

export const transformEndDateToMidnight = (date: Date | string): Date => {
	const newDate = new Date(date)
	newDate.setHours(23,59)
	return newDate
};

const transformPlannerEndDates = (data: any) => {
	if (!data) return null;

	const a = {
		...data,
		events: data.events.map((e: any) => ({
			...e,
			endDate: transformEndDateToMidnight(e.endDate)
		}))
	};

	return a;
};

export const getPlannerData =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: any } = await fetchPlannerData();
			if (response.data) {
				 dispatch(setPlannerData(transformPlannerEndDates(response.data)));
			} else {
				dispatch(setPlannerError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setPlannerError(error as OriginalResponse));
		}
	};

export const clearPlanData =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(clearPlannerData());
	};

export const clearAllStoredData =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(clearAllData());
	};

export const setLoadingTrue =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		dispatch(setLoading());
	};

// export const setLoadingFalse =
// 	() =>
// 	async (dispatch: Dispatch): Promise<void> => {
// 		dispatch(setPlannerLoading());
// 	};

export const getFiltersData =
	() =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const response: { data?: any } = await fetchFiltersData();
			if (response.data) {
				dispatch(
					!response.data.events
						? setFiltersData(response.data)
						: setFiltersData(transformPlannerEndDates(response.data))
				);
			} else {
				dispatch(setPlannerError(response as OriginalResponse));
			}
		} catch (error) {
			dispatch(setPlannerError(error as OriginalResponse));
		}
	};

export const savePlannerNewData =
	(payload: IEvent[], isCreate = false) =>
	async (dispatch: Dispatch): Promise<void> => {
		const eventsPayload = { events: payload };
		try {
			dispatch(setLoading());
			const response: Success = await postPlannerData(eventsPayload);
			dispatch(isCreate === false ? setPostNewPlannerData(response.data || null) : setPlannerDataCreate(response.data || null));
		} catch (error) {
			dispatch(setPostNewPlannerData(null));
		}
	};

export const getFilteredPlannerData =
	(payload: IFilters) =>
	async (dispatch: Dispatch): Promise<void> => {
		try {
			dispatch(setLoading());
			const { data }: Success = await postFilters(payload);
			if (data) {
				dispatch(setFilteredData(transformPlannerEndDates(data)));
			} else {
				dispatch(setPlannerError(data as OriginalResponse));
			}
		} catch (error) {
			dispatch(setPlannerError(error as OriginalResponse));
		}
	};

export const clearPlannerError =
	() =>
	async (dispatch: Dispatch): Promise<any> => {
		dispatch(setPlannerError(null));
	};
