import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
	getPeriodListFiltersData,
	getWeekViewData,
	getWeekViewDataForCopy,
	getDayViewData,
	printTimesheet,
	moveTimeEntry,
	copySave,
	exportTimesheet,
	saveTimesheet,
	attachmentList,
	deleteAttachment,
	downloadAttachment,
	addAttach,
	downloadImportTemplate
} from 'store/personalInfo/thunks';
import { getGuideData, getUserGuideData, setUserGuideAsRead } from 'store/guides/thunks';
import { AppState } from 'typings/state';

import MyTimesheets from './MyTimesheets';
import { setClearCopyMessage, setDeleteMoveTimeEntryMessage } from 'store/personalInfo/actions';
import { setNotificationAsRead } from 'store/notifications/thunks';
import { setMyTimesheetsFilter } from 'store/filters/actions';

const mapStateToProps = ({ personalInfo, filters, guides }: AppState) => ({ personalInfo, filters, guides });

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getPeriodListFiltersData,
			getWeekViewData,
			getWeekViewDataForCopy,
			getDayViewData,
			printTimesheet,
			moveTimeEntry,
			copySave,
			exportTimesheet,
			saveTimesheet,
			attachmentList,
			deleteAttachment,
			downloadAttachment,
			addAttach,
			getGuideData,
			getUserGuideData,
			setUserGuideAsRead,
			setDeleteMoveTimeEntryMessage,
			downloadImportTemplate,
			setNotificationAsRead,
			setMyTimesheetsFilter,
			setClearCopyMessage
		},
		dispatch
	);

export type MyTimesheetsProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MyTimesheets);
