/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
//* EXTERNAL LIBS
import React, { FC, useEffect, useMemo, useState } from 'react';

//* EXTERNAL LIBS --> MUI
import { Subject } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import Adicionar from '../../../../assets/icons/Adicionar.svg';

//* EXTERNAL LIBS --> XPAND-UI
import { BackPage, Dialog, LoadingOverlay, PageTitle, Tab, TabHeader, TabPanel, Table } from 'xpand-ui/core';
import { Numeric } from 'xpand-ui/forms';
import { parseDateToShow } from 'xpand-ui/utils/dates';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
import { IPageTitle } from 'components/App/TitleProvider';
import { ICollaborationProposal, IProposalSummaryPayload } from 'typings/store/admin/proposals';
import { ITableAction, ITableColumn } from 'typings/store/ComponentLib';
import { IUserToken, Match } from 'typings/store/generalTypes';

//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { Roles, actionPermission } from 'lib/roles';
import { allStatus } from 'pages/Admin/_ProposalsFormUtils/utils';

//* LOCAL COMPONENT IMPORTS
import { EmployeeSalaryCategoriesListProps } from '.';
import CscAuditLog from './CscAuditLog';
import DetailsModal from './DetailsModal';
import DetailsModalHistory from './DetailsModalHistory';
import { useStyles } from './styles';

//* LOCAL ASSETS
import imageURL from '../../../../assets/images/status diagrams/Diagram - Employee Salary Categories - XPECP.png';

import { getLSField } from 'lib/utils/cookies';
import { useWindowDimensions } from 'xpand-ui/utils/hooks';
//* COMPONENT INTERFACES
interface IEmployeeSalaryCategoriesList extends EmployeeSalaryCategoriesListProps, IPageTitle {
	match: Match<{
		hash: string;
	}>;
	goToPage: (path: string) => void;
	loggedUser: IUserToken;
}

const STATUS_NEW = 0;
const STATUS_APPROVED = 1;
// const STATUS_SENT = 2;
const STATUS_CANCELED = 3;
// const STATUS_REFUSED = 4;
// const STATUS_ACCEPTED = 5;
const STATUS_CLOSED = 6;
const STATUS_WAITING_APPROVAL = 7;
const STATUS_REJECTED = 8;

const tabs = [
	{
		id: 'IN_PROGRESS',
		label: 'IN PROGRESS'
	},
	{
		id: 'HISTORY',
		label: 'HISTORY'
	}
];
interface Action {
	id: number;
	label: string;
}

export const AVAILABLE_ACTIONS = {
	REQUEST_APPROVAL: { id: 0, label: 'Request Approval' },
	CLOSE: { id: 1, label: 'Close CSC' },
	CANCEL: { id: 2, label: 'Cancel CSC' },
	APPROVE: { id: 3, label: 'Approve CSC' },
	REJECT: { id: 4, label: 'Reject CSC' }
};

const notificationPayload = {
	area: 'Salaries & Contracts',
	section: 'Salary Categories'
};

//* COMPONENT
const EmployeeSalaryCategoriesList: FC<IEmployeeSalaryCategoriesList> = ({
	employeeSalary,
	match,
	filters,
	goToPage,
	title,
	setTitle,
	loggedUser,
	getEmployeeSalaryCategoriesList,
	submitEmployeeSalaryCategoryClose,
	submitEmployeeSalaryCategoryCancel,
	submitEmployeeSalaryCategoryRequestApproval,
	clearCscError,
	setNotificationAsReadProposals,
	setNewNotificationProposals
}) => {
	const classes = useStyles();
	const { loading, employeeSalaryCategoriesList, error } = employeeSalary;
	const { params } = match;
	const hash = params?.hash;
	const { width } = useWindowDimensions();
	const isTablet = useMemo(() => width <= 1457, [width]); // default is 900 tablet
	const [tabSelected, setTabSelected] = useState(0);
	const [detailsModal, setDetailsModal] = useState<ICollaborationProposal | null>(null);
	const [detailsModalHistory, setDetailsModalHistory] = useState<ICollaborationProposal | null>(null);
	const [auditModal, setAuditModalModal] = useState<ICollaborationProposal | null>(null);

	const [searchValue, setSearchValue] = useState('');
	const [cscHistorySearchValue, setCscHistorySearchValue] = useState('');

	// CONFIRMATION DIALOG HELPER
	const [openConfirmationModal, setOpenConfirmationModal] = useState<{
		row: ICollaborationProposal | null;
		isOpen: boolean;
		action: Action | null;
	}>({ action: null, isOpen: false, row: null });

	// Status Diagram Modal
	const [diagramModal, setDiagramModal] = useState<boolean>(false);

	// TABS COMPONENT - handle method
	const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => setTabSelected(newValue);

	useEffect(() => {
		if (hash && !error && (!employeeSalaryCategoriesList || !employeeSalaryCategoriesList[hash]))
			getEmployeeSalaryCategoriesList(hash);
	}, [employeeSalaryCategoriesList, hash]);

	useEffect(() => {
		if (employeeSalaryCategoriesList && employeeSalaryCategoriesList[hash]) {
			const newTitleString = !employeeSalaryCategoriesList[hash]?.userInfo?.username
				? 'Employee Salary Categories'
				: `Employee Salary Categories - ${`${employeeSalaryCategoriesList[hash]?.userInfo?.username}`.toUpperCase()} : ${
						employeeSalaryCategoriesList[hash]?.employeeInfo?.name
				  }`;

			newTitleString !== title.title &&
				setTitle({
					...title,
					title: newTitleString
				});
		}
	}, [employeeSalaryCategoriesList, hash, title]);

	const hasApprovePermission = useMemo(
		() =>
			employeeSalaryCategoriesList &&
			employeeSalaryCategoriesList[hash] &&
			actionPermission(Roles.CP_ROLE_PAYROLL_AND_BENEFITS),
		[title]
	);
	const hasRejectPermission = useMemo(
		() =>
			employeeSalaryCategoriesList &&
			employeeSalaryCategoriesList[hash] &&
			actionPermission(Roles.CP_ROLE_PAYROLL_AND_BENEFITS),
		[title]
	);

	const statusHelper = useMemo(() => allStatus(classes), []);

	// PAGE HEADER ACTIONS
	const pageTitleActions = useMemo(
		() => [
			{
				id: 'addNewCsc',
				to: `/admin/salariesAndContracts/newEmployeeSalary/${hash}`,
				onClick: () => goToPage(`/admin/salariesAndContracts/newEmployeeSalary/${hash}`),
				icon: <Adicionar />,
				label: 'Add new Employee Salary Category',
				disabled: getLSField('impersonate_userInfo')
			}
		],
		[]
	);

	/** CSCs IN PROGRESS TABLE */
	// TABLE IN PROGRESS ROW ACTIONS
	const tableActions: ITableAction<ICollaborationProposal>[] = useMemo(
		() => [
			{
				id: 'detailsAction',
				type: 'button',
				icon: <Subject />,
				render: () => true,
				disabled: () => false,
				onClick: row => setDetailsModal(row)
			},
			{
				id: 'proposalActions',
				type: 'menu',
				render: () => true,
				options: [
					{
						id: 'edit',
						label: 'Edit',
						render: row => row.proposalStatus === STATUS_NEW || row.proposalStatus === STATUS_REJECTED,
						to: row => `/cp/admin/salariesAndContracts/editEmployeeSalary/${hash}/${row.token}/${row.id}`,
						onClick: row =>
							goToPage(`/admin/salariesAndContracts/editEmployeeSalary/${hash}/${row.token}/${row.id}`)
					},
					{
						id: 'auditLog',
						label: 'Audit Log',
						render: () => true,
						onClick: row => setAuditModalModal(row)
					},
					{
						id: 'requestApproval',
						label: 'Request Approval',
						render: row => row.proposalStatus === STATUS_NEW || row.proposalStatus === STATUS_REJECTED,
						onClick: row =>
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.REQUEST_APPROVAL, isOpen: true, row })
					},
					{
						id: 'approve',
						label: 'Approve',
						render: row => row.proposalStatus === STATUS_WAITING_APPROVAL,
						disabled: () => !hasApprovePermission, // if has permission, then disable is !(hasPermission)
						onClick: row =>
							hasApprovePermission &&
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.APPROVE, isOpen: true, row })
					},
					{
						id: 'reject',
						label: 'Reject',
						render: row => row.proposalStatus === STATUS_WAITING_APPROVAL,
						disabled: () => !hasRejectPermission, // if has permission, then disable is !(hasPermission)
						onClick: row =>
							hasRejectPermission &&
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.REJECT, isOpen: true, row })
					},
					{
						id: 'close',
						label: 'Close',
						render: row => row.proposalStatus === STATUS_APPROVED,
						onClick: row => setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.CLOSE, isOpen: true, row }) // submitEmployeeSalaryCategoryClose(row.token, row.id)
					},
					{
						id: 'cancel',
						label: 'Cancel',
						render: row => row.proposalStatus !== STATUS_CANCELED && row.proposalStatus !== STATUS_CLOSED,
						onClick: row =>
							setOpenConfirmationModal({ action: AVAILABLE_ACTIONS.CANCEL, isOpen: true, row }) // submitEmployeeSalaryCategoryCancel(row.token, row.id)
					}
				]
			}
		],
		[hasApprovePermission, hasRejectPermission]
	);
	// TABLE IN PROGRESS DATA PARSED
	const tableData = useMemo(() => {
		if (!employeeSalaryCategoriesList || !employeeSalaryCategoriesList[hash]) return [];

		return employeeSalaryCategoriesList[hash]?.proposalsPerToken?.map((e: IProposalSummaryPayload) => ({
			...e,
			monthlyGrossValue: e.summary?.monthlyGrossValue || '',
			monthlyNetValue: e.summary?.monthlyNetValue || '',
			annualGrossCostWithPrize: e.summary?.annualGrossCostWithPrize || '',
			startDateParsed: e.startDate ? parseDateToShow(e.startDate) : '',
			endDateParsed: e.endDate ? parseDateToShow(e.endDate) : '',
			status: e.proposalStatus || '',
			diffMonthlyNet: e.summary?.monthlyNetValue + ' ' + Number(e.diffPercentageMontlyNet).toFixed(2) + '%',
			diffTotalCost: Number(e.diffPercentageTotalCost).toFixed(2) + '%'
		}));
	}, [employeeSalaryCategoriesList]);

	// TABLe IN PROGRESS COLUMNS
	const tableColumns: ITableColumn<IProposalSummaryPayload>[] = useMemo(
		() => [
			{
				label: 'Start Date',
				id: 'startDate',
				type: 'date',
				width: '10%',
				format: (row, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'End Date',
				id: 'endDate',
				type: 'date',
				width: '10%',
				format: (row, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'Monthly Gross Amount',
				id: 'monthlyGrossValue',
				align: 'left',
				width: '20%',
				format: ({ summary }) => <Numeric readOnly money value={summary?.monthlyGrossValue} />
			},
			{
				label: 'Monthly Net Amount',
				id: 'monthlyNetValue',
				align: 'left',
				width: '20%',
				format: ({ summary, diffPercentageMontlyNet }) => {
					const monthlyNet =
						Number(diffPercentageMontlyNet) > 0
							? '+' + Number(diffPercentageMontlyNet).toFixed(2)
							: Number(diffPercentageMontlyNet).toFixed(2);
					return (
						<>
							{!isTablet ? (
								<Grid container direction="row" justifyContent="flex-start" alignItems="center">
									<Grid item xs={6}>
										<Numeric readOnly money value={summary?.monthlyNetValue} />
									</Grid>
									{diffPercentageMontlyNet && (
										<Grid item xs={6}>
											<label
												style={{
													color: Number(diffPercentageMontlyNet) < 0 ? 'red' : 'green'
												}}>
												({monthlyNet}
												%)
											</label>
										</Grid>
									)}
								</Grid>
							) : (
								<>
									<Numeric readOnly money value={summary?.monthlyNetValue} />
									{diffPercentageMontlyNet && (
										<label
											style={{
												color: Number(diffPercentageMontlyNet) < 0 ? 'red' : 'green'
											}}>
											({monthlyNet}
											%)
										</label>
									)}
								</>
							)}
						</>
					);
				}
			},

			{
				label: 'Total Cost (with Prize)',
				id: 'annualGrossCostWithPrize',
				align: 'left',
				width: '20%',
				format: ({ summary, diffPercentageTotalCost }) => {
					const totalCost =
						Number(diffPercentageTotalCost) > 0
							? '+' + Number(diffPercentageTotalCost).toFixed(2)
							: Number(diffPercentageTotalCost).toFixed(2);
					return (
						<>
							{!isTablet ? (
								<Grid container direction="row" justifyContent="flex-start" alignItems="center">
									<Grid item xs={6}>
										<Numeric readOnly money value={summary?.annualGrossCostWithPrize} />{' '}
									</Grid>
									{diffPercentageTotalCost && (
										<Grid item xs={6}>
											<label
												style={{
													color: Number(diffPercentageTotalCost) < 0 ? 'red' : 'green'
												}}>
												({totalCost}
												%)
											</label>
										</Grid>
									)}
								</Grid>
							) : (
								<>
									<Numeric readOnly money value={summary?.annualGrossCostWithPrize} />
									{diffPercentageTotalCost && (
										<label
											style={{
												color: Number(diffPercentageTotalCost) < 0 ? 'red' : 'green'
											}}>
											({totalCost}
											%)
										</label>
									)}
								</>
							)}
						</>
					);
				}
			},
			{
				label: 'Status',
				id: 'status',
				width: '15%',
				format: ({ proposalStatus }) => {
					if (proposalStatus === 99)
						return (
							<div className={classes.statusBar}>
								{statusHelper[9].icon} {statusHelper[9].label}
							</div>
						);
					if (statusHelper[proposalStatus])
						return (
							<div className={classes.statusBar}>
								{statusHelper[proposalStatus].icon} {statusHelper[proposalStatus].label}
							</div>
						);

					return <div className={classes.statusBar}>{proposalStatus}</div>;
				}
			}
		],
		[employeeSalaryCategoriesList]
	);

	/** CSCs HISTORY TABLE */
	// TABLE HISTORY ROW ACTIONS
	const tableCSCHistoryActions = useMemo(
		() => [
			{
				id: 'historyDetailsAction',
				type: 'button',
				icon: <Subject />,
				render: () => true,
				disabled: () => false,
				onClick: (row: ICollaborationProposal) =>
					setDetailsModalHistory({ ...employeeSalaryCategoriesList[hash].employeeInfo, ...row })
			}
		],
		[employeeSalaryCategoriesList]
	);

	// TABLE HISTORY DATA PARSED
	const tableCSCHistoryData = useMemo(() => {
		if (!employeeSalaryCategoriesList || !employeeSalaryCategoriesList[hash]) return [];

		return employeeSalaryCategoriesList[hash]?.cscHistory?.map((e: any) => ({
			...e,
			startingDateParsed: e.startingDate ? parseDateToShow(e.startingDate) : '',
			xPHRDateFinish: e.xPHRDateFinish || '',
			xPHRDateFinishParsed: e.xPHRDateFinish ? parseDateToShow(e.xPHRDateFinish) : ''
		}));
	}, [employeeSalaryCategoriesList]);

	// TABLE HISTORY COLUMNS
	const tableCSCHistoryColumns: ITableColumn<ICollaborationProposal>[] = useMemo(
		() => [
			{
				label: 'Start Date',
				id: 'startingDate',
				type: 'date',
				width: '10%',
				format: (row: ICollaborationProposal, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'End Date',
				id: 'xPHRDateFinish',
				type: 'date',
				width: '10%',
				format: (row: ICollaborationProposal, text: string) => (text ? parseDateToShow(text) : '')
			},
			{
				label: 'Monthly Gross Amount',
				id: 'xphrMonthlyGrossVal',
				accentColumn: true,
				align: 'left',
				width: '20%',
				format: (row: ICollaborationProposal, text: string) => <Numeric readOnly money value={text} />
			},
			{
				label: 'Monthly Net Amount',
				id: 'xphrMonthlyNetAmount',
				accentColumn: true,
				align: 'left',
				width: '20%',
				format: ({ xphrMonthlyNetAmount, diffMontlyNetAmount }) => {
					const monthlyNetAmount =
						Number(diffMontlyNetAmount) > 0
							? '+' + Number(diffMontlyNetAmount).toFixed(2)
							: Number(diffMontlyNetAmount).toFixed(2);
					return (
						<>
							{isTablet ? (
								<Grid container direction="row" justifyContent="flex-start" alignItems="center">
									<Grid item>
										<Numeric readOnly money value={xphrMonthlyNetAmount} />
									</Grid>
									{diffMontlyNetAmount &&
										diffMontlyNetAmount != Infinity &&
										!isNaN(diffMontlyNetAmount) && (
											<Grid item>
												<label
													style={{
														color: Number(diffMontlyNetAmount) < 0 ? 'red' : 'green'
													}}>
													({monthlyNetAmount}
													%)
												</label>
											</Grid>
										)}
								</Grid>
							) : (
								<>
									<Numeric readOnly money value={xphrMonthlyNetAmount} />
									{diffMontlyNetAmount &&
										diffMontlyNetAmount != Infinity &&
										!isNaN(diffMontlyNetAmount) && (
											<label
												style={{
													color: Number(diffMontlyNetAmount) < 0 ? 'red' : 'green'
												}}>
												({monthlyNetAmount}
												%)
											</label>
										)}
								</>
							)}
						</>
					);
				}
			},
			{
				label: 'Total Cost (with Prize)',
				id: 'xphrAnnualGrossCostPriz',
				accentColumn: true,
				align: 'left',
				width: '20%',
				format: ({ xphrAnnualGrossCostPriz, diffTotalCost }) => {
					const totalCost =
						Number(diffTotalCost) > 0
							? '+' + Number(diffTotalCost).toFixed(2)
							: Number(diffTotalCost).toFixed(2);
					return (
						<>
							{!isTablet ? (
								<Grid container direction="row" justifyContent="flex-start" alignItems="center">
									<Grid item>
										<Numeric readOnly money value={xphrAnnualGrossCostPriz} />
									</Grid>
									{diffTotalCost && diffTotalCost != Infinity && !isNaN(diffTotalCost) && (
										<Grid item>
											<label
												style={{
													color: Number(diffTotalCost) < 0 ? 'red' : 'green'
												}}>
												({totalCost}
												%)
											</label>
										</Grid>
									)}
								</Grid>
							) : (
								<>
									<Numeric readOnly money value={xphrAnnualGrossCostPriz} />
									{diffTotalCost && diffTotalCost != Infinity && !isNaN(diffTotalCost) && (
										<label
											style={{
												color: Number(diffTotalCost) < 0 ? 'red' : 'green'
											}}>
											({totalCost}
											%)
										</label>
									)}
								</>
							)}
						</>
					);
				}
			}
		],
		[employeeSalaryCategoriesList]
	);

	const requestAction = (action: Action) => {
		if (!action || !action.label) {
			return;
		}
		const { row } = openConfirmationModal;

		switch (action.id) {
			case AVAILABLE_ACTIONS.REQUEST_APPROVAL.id:
				row && submitEmployeeSalaryCategoryRequestApproval(hash, row.token as string, row.id);
				break;
			case AVAILABLE_ACTIONS.CLOSE.id:
				row && submitEmployeeSalaryCategoryClose(hash, row.token as string, row.id);
				setNotificationAsReadProposals(row.id.toString(), notificationPayload);
				break;
			case AVAILABLE_ACTIONS.APPROVE.id:
				row &&
					goToPage(
						`/admin/salariesAndContracts/employeeSalaryCategories/approve/${row.token}/${row.id}/${loggedUser.username}`
					);

				break;
			case AVAILABLE_ACTIONS.REJECT.id:
				row &&
					goToPage(
						`/admin/salariesAndContracts/employeeSalaryCategories/reject/${row.token}/${row.id}/${loggedUser.username}`
					);

				break;
			case AVAILABLE_ACTIONS.CANCEL.id:
				row && submitEmployeeSalaryCategoryCancel(hash, row.token as string, row.id);
				setNotificationAsReadProposals(row.id.toString(), notificationPayload);
				break;
			default:
		}
	};

	const getModalMessage = (action: Action) =>
		action ? `Are you sure you want to execute the following action: ${action.label} ` : '';

	const confirmActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => setOpenConfirmationModal({ action: openConfirmationModal.action, isOpen: false, row: null })
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				setOpenConfirmationModal({ action: openConfirmationModal.action, isOpen: false, row: null });
				requestAction(openConfirmationModal.action as Action);
			},
			disabled: getLSField('impersonate_userInfo')
		}
	];

	if (error) return handleErrorPage(error, clearCscError);
	const isLoading = employeeSalaryCategoriesList === null;

	if (isLoading) return <LoadingOverlay />;

	return (
		<>
			{loading && <LoadingOverlay />}

			<BackPage path="/admin/salariesAndContracts/employeeSalaryCategories" action={goToPage} />
			<Grid
				container
				direction="row"
				justifyContent="space-evenly"
				alignItems="flex-start"
				spacing={5}
				className={classes.root}>
				<PageTitle
					asItem
					title={
						<TabHeader
							selected={tabSelected}
							handleSelected={handleTabChange}
							variant="scrollable"
							scrollButtons="auto">
							{tabs.map((tab, index) => (
								<Tab
									key={`EmployeeSalaryCategoriesList_${tab.id}_tab_button`}
									label={tab.label}
									index={index}
								/>
							))}
						</TabHeader>
					}
					actions={pageTitleActions}
				/>
				<Grid item {...sizes[12]}>
					{tabs.map((tab, index) => (
						<TabPanel
							key={`EmployeeSalaryCategoriesList_${tab.id}_tab_content`}
							value={tabSelected}
							index={index}
							className={classes.tabsRoot}>
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								alignItems="baseline"
								spacing={2}>
								{tabSelected === 0 && (
									<Table
										tableData={tableData?.length > 0 ? tableData : []}
										columns={tableColumns}
										actions={tableActions}
										defaultSortColumn="startDate"
										defaultOrder="desc"
										handleSearch={{ searchValue, setSearchValue }}
										handleDiagramModal={{ diagramModal, setDiagramModal }}
									/>
								)}
								{tabSelected === 1 && (
									<>
										<Table
											tableData={tableCSCHistoryData?.length > 0 ? tableCSCHistoryData : []}
											columns={tableCSCHistoryColumns}
											actions={tableCSCHistoryActions}
											handleSearch={{
												searchValue: cscHistorySearchValue,
												setSearchValue: setCscHistorySearchValue
											}}
										/>
									</>
								)}
							</Grid>
						</TabPanel>
					))}
				</Grid>
			</Grid>
			<Dialog
				fullScreen
				// customClasses={{ fullScreenContent: classes.fullScreenContent }}
				title=""
				scroll="body"
				modal={{
					open: Boolean(detailsModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setDetailsModal(null);
					},
					content:
						(Boolean(detailsModal) && (
							<DetailsModal
								proposal={detailsModal as ICollaborationProposal}
								hash={hash}
								handleClose={setDetailsModal}
							/>
						)) ||
						''
				}}
			/>
			<Dialog
				fullScreen
				// customClasses={{ fullScreenContent: classes.fullScreenContent }}
				title=""
				scroll="body"
				modal={{
					open: Boolean(auditModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setAuditModalModal(null);
					},
					content:
						(auditModal && (
							<CscAuditLog
								id={auditModal.id}
								hash={hash}
								ESCName={`Employee Salary Category - ${`${employeeSalaryCategoriesList[hash]?.userInfo?.username}`.toUpperCase()} : ${
									employeeSalaryCategoriesList[hash]?.employeeInfo?.name
								} - Audit Log`}
								token={auditModal.token as string}
								handleClose={setAuditModalModal}
							/>
						)) ||
						''
				}}
			/>
			<Dialog
				fullScreen
				// customClasses={{ fullScreenContent: classes.fullScreenContent }}
				title=""
				scroll="body"
				modal={{
					open: Boolean(detailsModalHistory),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setDetailsModalHistory(null);
					},
					content:
						(Boolean(detailsModalHistory) && (
							<DetailsModalHistory
								proposal={detailsModalHistory as ICollaborationProposal}
								hash={hash}
								handleClose={setDetailsModalHistory}
							/>
						)) ||
						''
				}}
			/>
			<Dialog
				modal={{
					open: Boolean(openConfirmationModal.isOpen),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick')
							setOpenConfirmationModal({
								action: openConfirmationModal.action,
								isOpen: false,
								row: null
							});
					},
					content: (
						<>
							<Typography gutterBottom>
								{getModalMessage(openConfirmationModal.action as Action)}
							</Typography>
						</>
					)
				}}
				title="Confirm action"
				actions={confirmActions}
				scroll="body"
			/>

			<Dialog
				title="Status Diagram"
				modal={{
					open: diagramModal,
					handleClose: () => {
						setDiagramModal(false);
					},
					content: (
						<>
							<img src={imageURL} className={classes.centerInParent} alt="" />
						</>
					)
				}}
				actions={[
					{
						id: 'closeModal',
						label: 'Close',
						color: 'primary',
						variant: 'contained',
						onClick: () => setDiagramModal(false)
					}
				]}
			/>
		</>
	);
};

export default withLayout(EmployeeSalaryCategoriesList);
