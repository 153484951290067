import React, { useState, useEffect, ReactNode } from 'react';
import { InputAdornment, FormControl, Typography, ButtonBase, Paper, Input, Grid, Card } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';

import { LoadingOverlay, NoDataSearch, NoData, Dialog } from 'xpand-ui/core';

import { filterOnlySelected } from 'xpand-ui/utils/sort';

import Bamboo from 'assets/images/apps/bamboo.svg';
import Bitbucket from 'assets/images/apps/bitbucket.svg';
import Confluence from 'assets/images/apps/confluence.svg';
import Jira from 'assets/images/apps/jira.svg';
import Office365 from 'assets/images/apps/office365.svg';
import Openbravo from 'assets/images/apps/openbravo.svg';
import Outlook from 'assets/images/apps/outlook.svg';
import ServiceDesk from 'assets/images/apps/serviceDesk.svg';
import SonarQube from 'assets/images/apps/sonarQube.svg';
import Teams from 'assets/images/apps/teams.svg';
import Xpand from 'assets/images/apps/xpand.svg';
import Leapsome from 'assets/images/apps/leapsome.svg';
import RDStation from 'assets/images/apps/rdStation.svg';
import SharePoint from 'assets/images/apps/sharePoint.svg';

import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

import { AppsProps } from './index';
import { useStyles } from './styles';

/** method to open the app link on a new window */
const openInNewTab = (url: string): void => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
	if (newWindow) newWindow.opener = null;
};

const AppsPage: React.FC<AppsProps> = ({
	general,
	filters,
	personalInfo,
	clearAppsError,
	getAppsList,
	setAppsPageFilter,
	getUserProfile,
	sendUserUpdate
}) => {
	const classes = useStyles();
	const { error, appsList } = general;
	const { companyAppsFilter } = filters;
	const { userProfile } = personalInfo;
	const [newReleaseAcknowledgeModal, setNewReleaseAcknowledgeModal] = useState(false);

	const [searchValue, setSearchValue] = useState(companyAppsFilter);

	// Update filters whenever necessary
	useEffect(() => {
		setAppsPageFilter(searchValue);
	}, [searchValue]);

	// when the page is mounted, check if the field already have values
	// if found values, ignore
	// is the field is empty or null, (re)do the request to fetch the info
	useEffect(() => {
		if (!error) getAppsList();
	}, []);

	useEffect(
		() => () => {
			clearAppsError();
		},
		[]
	);

	useEffect(() => {
		if (!userProfile) getUserProfile('');
	}, [userProfile]);

	useEffect(() => {
		if (userProfile && !userProfile?.acknowledgedNewRelease) {
			setNewReleaseAcknowledgeModal(true);
		}
	}, [userProfile]);

	// common size for the cards on each resolution size
	const cardSizes = {
		xs: 6,
		sm: 6,
		md: 4,
		lg: 3,
		xl: 2
	};

	const filterData = () => (appsList ? filterOnlySelected(appsList, searchValue, ['title', 'searchKey']) : null);

	const parsedData = filterData();

	const handleApiSubmit = (
		/* | IUpdateContacts
			| IUpdateCompanyAllocation
			| IUpdatePassword
			| IUpdatePersonalInfo
			| IUpdatePrivateData
			| IUpdateBankInfo
			| IUpdateFiscalInfo
			| IUpdateReadConsents */
		payload: any
	) => {
		sendUserUpdate(payload, userProfile?.user?.ldapData?.user?.username);
	};

	const submitAcknowlegedNewReleaseStatement = () => {
		setNewReleaseAcknowledgeModal(false);
		handleApiSubmit([
			{
				type: 'newReleaseStatement',
				data: {
					status: true
				}
			}
		]);
	};

	if (error) return handleErrorPage(error, clearAppsError);

	// manual loading state
	// (better manual than with render and redux, less memory used and more fast state updates)
	const isLoading = appsList === null;

	return (
		<>
			{isLoading && <LoadingOverlay />}
			{appsList && appsList?.length > 0 ? (
				<>
					<Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
						<Grid item xs={8} />
						<Grid item xs={4}>
							<Paper component="form" className={classes.searchTableInput}>
								<FormControl>
									<Input
										className={classes.input}
										autoFocus
										disableUnderline
										placeholder="Search"
										classes={{ focused: classes.inputFocused }}
										value={searchValue}
										onChange={event => {
											setSearchValue(event.target.value);
										}}
										endAdornment={
											searchValue === '' ? (
												<InputAdornment className={classes.search} position="end">
													<Search />
												</InputAdornment>
											) : (
												<InputAdornment
													className={classes.search}
													position="end"
													style={{ cursor: 'pointer' }}
													onClick={() => {
														setSearchValue('');
													}}>
													<Clear />
												</InputAdornment>
											)
										}
									/>
								</FormControl>
							</Paper>
						</Grid>
					</Grid>
					<Grid
						container
						className={classes.root}
						rowSpacing={2}
						columnSpacing={2}
						direction="row"
						justifyContent="flex-start"
						alignItems="flex-start">
						{parsedData &&
							parsedData.length > 0 &&
							(parsedData as { title: string; searchKey: string; url: string; image: any }[]).map(
								({ title, searchKey, url, image }) => (
									<Grid key={searchKey} component="div" item {...cardSizes}>
										<ButtonBase onClick={() => openInNewTab(url)} className={classes.cardBase}>
											<Card className={classes.cardRoot}>
												<img src={`data:image/svg+xml;base64,${image}`} />
												<Typography
													className={classes.appText}
													gutterBottom
													variant="h5"
													component="h2">
													{title}
												</Typography>
											</Card>
										</ButtonBase>
									</Grid>
								)
							)}
						<Grid
							container
							className={classes.centerNoResults}
							spacing={4}
							direction="row"
							justifyContent="flex-start"
							alignItems="flex-start">
							{parsedData?.length === 0 && <NoDataSearch />}
						</Grid>
					</Grid>
					<Dialog
						title="New Release Acknowledgement"
						modal={{
							open: newReleaseAcknowledgeModal,
							handleClose: () => {},
							content: (
								<>
									<Typography gutterBottom align="justify">
										A new release of Control Panel has been deployed, please clear your browser
										cache to avoid any possible conflicts.
									</Typography>
								</>
							)
						}}
						actions={[
							{
								id: 'confirmReadNewReleasePopup',
								label: 'Confirm',
								color: 'primary',
								variant: 'contained',
								onClick: submitAcknowlegedNewReleaseStatement
							}
						]}
					/>
				</>
			) : (
				<Grid>
					<NoData />
				</Grid>
			)}
		</>
	);
};

export default withLayout(AppsPage);
