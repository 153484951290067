/* eslint-disable @typescript-eslint/no-explicit-any */
import { action } from 'typesafe-actions';
import { IDispatchType } from 'typings/store/storeTypes';
import { PlannerActions as Actions } from 'typings/store/admin/planner';
import { OriginalResponse } from 'lib/handlers/requestTypes';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);

export const setPlannerError = (payload: OriginalResponse | null): IDispatchType => action(Actions.SET_ERROR, payload);

export const clearPlannerData = (): IDispatchType => action(Actions.CLEAR_PLANNER_DATA);

export const clearAllData = (): IDispatchType => action(Actions.CLEAR_ALL_DATA);

export const setPlannerData = (payload: any | null): IDispatchType => action(Actions.GET_PLANNER_DATA, payload);

export const setFiltersData = (payload: any | null): IDispatchType => action(Actions.GET_FILTERS_DATA, payload);

export const setPostNewPlannerData = (payload: unknown): IDispatchType => action(Actions.POST_PLANNER_DATA, payload);

export const setFilteredData = (payload: any | null | []): IDispatchType => action(Actions.POST_FILTERS, payload);

export const setPlannerAction = (payload: any | null | []): IDispatchType => action(Actions.SET_PLANNER_ACTION, payload);

export const setPlannerDataCreate = (payload: any | null | []): IDispatchType => action(Actions.POST_PLANNER_DATA_CREATE, payload);
