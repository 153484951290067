import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { LoadingOverlay, DragDropGrid, Dialog } from 'xpand-ui/core';
import { Input, Checkbox } from 'xpand-ui/forms';
import { UserGuidesProps } from '.';
import React, { FC, useEffect, useState } from 'react';
import { Match } from 'typings/store/generalTypes';
import { useStyles } from './styles';
import { clearAdministrationError } from 'store/administration/administration/thunks';
import { Grid, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { defaultValues, schema } from './yupSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Close, Check } from '@mui/icons-material';
import { addNotification } from 'lib/utils/notifications';
import { Prompt } from 'react-router';

interface UserGuides extends UserGuidesProps {
	match: Match<{
		path?: string;
		username: string;
	}>;
	goToPage: (path: string) => void;
}
//* COMPONENT INTERFACES
export interface Item {
	id: string;
	content: string;
	isEditing: boolean;
	placement: string;
	target: string;
	title: string;
	disableScrolling: boolean;
	spotlightClicks: boolean;
}

export interface Column {
	id: string;
	title: string | undefined;
	isEnable: boolean;
	changedEnabledState: boolean;
	items: Item[];
	newItemContent: string;
	isEditing: boolean;
	showItems: boolean;
	isDeleted: boolean;
}

export interface Columns {
	[key: string]: Column;
}
interface Action {
	id: string;
	label: string;
	onClick: () => void;
	disabled?: boolean;
}

const UserGuides: FC<UserGuides> = ({ administration, getGuidesData, postUploadGuidesData }) => {
	// Import styles
	const classes = useStyles();

	// Destructure loading state from administration props
	const { guidesData, error } = administration;

	// Set up state for columns
	const [columns, setColumns] = useState({});
	const [guideStepFormModal, setGuideStepFormModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [hasChangesToBeSaved, setChangesToBeSaved] = useState(false);

	// Form setup using react-hook-form
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		reset,
		getValues,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema), // Form validation using Yup schema
		reValidateMode: 'onChange',
		defaultValues
	});

	// Fetch guides data on component mount
	useEffect(() => {
		getGuidesData();
	}, [getGuidesData]);

	// Initialize columns state when guidesData changes
	useEffect(() => {
		if (guidesData && guidesData.guides) {
			const initialColumns = guidesData.guides.reduce((acc: any, guide: any) => {
				acc[guide.guideId.toString()] = {
					id: guide.guideId.toString(),
					title: guide.guideName,
					isEnable: guide.isEnable,
					items: guide.steps.map((step: any) => ({
						id: step.stepId.toString(),
						content: step.content,
						placement: step.placement,
						target: step.target,
						title: step.title,
						disableScrolling: step.disableScrolling,
						spotlightClicks: step.spotlightClicks,
						isEditing: false
					})),
					newItemContent: '',
					isEditing: false,
					showItems: true,
					changedEnabledState: false,
					isDeleted: false
				};
				return acc;
			}, {});
			setColumns(initialColumns);
		}
	}, [guidesData]);

	// Edit specific step in guide
	const editStepGuid = (itemId: string) => {
		Object.keys(columns).forEach(columnId => {
			columns[columnId].items.forEach(item => {
				if (item.id == itemId) {
					setSelectedItem(item);
					setValue('content', item.content);
					setValue('placement', item.placement);
					setValue('target', item.target);
					setValue('title', item.title);
					setValue('disableScrolling', item.disableScrolling);
					setValue('spotlightClicks', item.spotlightClicks);
				}
			});
		});
		setGuideStepFormModal(true);
	};

	// Enable or disable guide column
	const enableDisableGuide = (colId: string) => {
		const updatedColumns = { ...columns };
		Object.keys(updatedColumns).forEach(column => {
			if (column === colId) {
				updatedColumns[column].isEnable = !updatedColumns[column].isEnable;
				updatedColumns[column].changedEnabledState = true;

				// Add notification based on enable/disable state
				addNotification(
					'info',
					updatedColumns[column].isEnable
						? 'Guide will be enabled for all users after you save all changes'
						: 'Guide will be disabled for all users after you save all changes',
					5
				);
			}
		});
		setChangesToBeSaved(true);
		setColumns(updatedColumns);
	};

	// Check if guide column is enabled
	const checkIsEnable = (colId: string) => {
		if (columns[colId] && columns[colId].isEnable) {
			return true;
		}
		return false;
	};

	// Actions available in the Step Guide Form popup
	const confirmActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setGuideStepFormModal(false);
				reset(defaultValues);
				setSelectedItem(null);
			}
		},
		{
			id: 'submit',
			label: 'Update Guide Step',
			color: 'primary',
			variant: 'contained',
			onClick: () => {},
			type: 'submit',
			form: 'form-guide-step'
		}
	];

	// Submit handler for updating step in guide
	const submitStepFormHandler = async (payloadForm: any) => {
		const updatedColumns = { ...columns };

		Object.keys(updatedColumns).forEach(columnId => {
			updatedColumns[columnId].items.forEach(item => {
				if (item.id === selectedItem?.id) {
					item.content = payloadForm.content;
					item.placement = payloadForm.placement;
					item.target = payloadForm.target;
					item.title = payloadForm.title;
					item.disableScrolling = payloadForm.disableScrolling;
					item.spotlightClicks = payloadForm.spotlightClicks;
				}
			});
		});
		setGuideStepFormModal(false);
		setChangesToBeSaved(true);
		setColumns(updatedColumns);
		setSelectedItem(null);
		reset(defaultValues);
	};

	// Handle adding a new guide column
	const handleAddColumn = () => {
		const newColumnId = `column-${new Date().getTime()}`;
		const newColumn: Column = {
			id: newColumnId,
			title: 'New Guide',
			items: [],
			newItemContent: '',
			isEditing: false,
			isEnable: true,
			showItems: true,
			changedEnabledState: false,
			isDeleted: false
		};
		setChangesToBeSaved(true);
		setColumns({
			...columns,
			[newColumnId]: newColumn
		});
	};

	// Handle uploading changes to server
	const handleUploadChanges = () => {
		setChangesToBeSaved(false);
		postUploadGuidesData(columns);
	};

	// Extra actions for header based on guide column state
	const extraHearderActions = (handleCloseHeaderAction: () => void, columnId: any): Action[] => {
		return [
			{
				id: 'disableGuide',
				label: checkIsEnable(columnId) ? (
					<>
						<Close className={classes.columnIcon} />
						&nbsp; Disable Guide
					</>
				) : (
					<>
						<Check className={classes.columnIcon} />
						&nbsp; Enable Guide
					</>
				),
				onClick: () => {
					enableDisableGuide(columnId);
					handleCloseHeaderAction();
				}
			}
		];
	};

	if (error) return handleErrorPage(error, clearAdministrationError);

	const isLoading = guidesData === null;

	if (isLoading) return <LoadingOverlay />;

	//! exit warning
	return (
		<>
			<DragDropGrid
				disableAddColumn
				gridFullScreen
				renameColumnLabel="Rename Guide"
				deleteColumnLabel="Delete Guide"
				hideItemsLabel="Hide Guide Steps"
				showItemsLabel="Show Guide Steps"
				addItemPlaceholderLabel="Add New Guide Step"
				duplicateItemLabel="Duplicate Guide Step"
				renameItemLabel="Edit Guide Step"
				deleteItemLabel="Delete Guide Step"
				columns={columns}
				setColumns={setColumns}
				setChangesToBeSaved={setChangesToBeSaved}
				additionalHandleEditItem={editStepGuid}
				extraHearderActions={extraHearderActions}
			/>
			{/* Footer of the page */}
			<Grid container direction="row" id={'footerButtonsID'} className={classes.footer} alignItems="center">
				<Button size="large" onClick={handleAddColumn}>
					Add User Guide
				</Button>
				<Button color="primary" size="large" variant="contained" onClick={handleUploadChanges}>
					Save Changes
				</Button>
			</Grid>
			{/* Dialog for Guide Step Form */}
			<Dialog
				modal={{
					open: Boolean(guideStepFormModal),
					handleClose: (event: unknown, reason: string) => {
						if (reason !== 'backdropClick') setGuideStepFormModal(false);
					},
					content: (
						<>
							<form
								id="form-guide-step"
								onSubmit={e => handleSubmit(() => submitStepFormHandler(watch()))(e)}>
								<Grid item xs={12}>
									<Grid item xs={12} className={classes.formFields}>
										<Input name="content" label="Content" control={control} errors={errors} />
									</Grid>
									<Grid item xs={12} className={classes.formFields}>
										<Input name="placement" label="Placement" control={control} errors={errors} />
									</Grid>
									<Grid item xs={12} className={classes.formFields}>
										<Input name="target" label="Target" control={control} errors={errors} />
									</Grid>
									<Grid item xs={12} className={classes.formFields}>
										<Input name="title" label="Title" control={control} errors={errors} />
									</Grid>
									<Grid item xs={12} className={classes.formFields}>
										<Checkbox
											name="disableScrolling"
											label="Disable Scrolling"
											control={control}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={12} className={classes.formFields}>
										<Checkbox
											name="spotlightClicks"
											label="Spotlight Clicks"
											control={control}
											errors={errors}
										/>
									</Grid>
								</Grid>
							</form>
						</>
					)
				}}
				title="Guide Step Form"
				actions={confirmActions}
				scroll="body"
			/>

			<Prompt when={hasChangesToBeSaved} message="Are you sure you want to leave this page without saving?" />
		</>
	);
};

export default withLayout(UserGuides);
