import { restCall } from 'lib/handlers/restHandler';
import { OriginalResponse, Success, GET, POST } from 'lib/handlers/requestTypes';
import { IEvent, IFilters } from 'typings/store/admin/planner';

export const fetchPlannerData = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/planner');

export const fetchFiltersData = async (): Promise<OriginalResponse | Success> => restCall(GET, '/admin/planner/filter');

export const postPlannerData = async (payload: { events: IEvent[] }): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/planner/save', payload);

export const postFilters = async (payload: IFilters): Promise<OriginalResponse | Success> =>
	restCall(POST, '/admin/planner/filter', payload);
